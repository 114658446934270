.default-card-border {
  border-top: 5px solid var(--color-theme);
}

.default-card-border .ant-upload {
  width: 100% !important;
  aspect-ratio: 1/1;
  height: auto !important;
  padding: 2px;
}

.default-card-border .ant-upload img {
  aspect-ratio: 1/1;
  height: auto !important;
  object-fit: contain;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  border-radius: 5px;
}

.default-card-border .input-box,
.default-card-border .input-box .ant-picker,
.default-card-border .input-box .ant-select-selector {
  height: 50px !important;
  border-radius: 5px !important;
  border-color: #bbbbbb !important;
  /* padding: 16.5px 14px; */
  /* height: 1.4375em !important; */
  width: 100% !important;
}

.default-card-border .input-box .ant-select-selector .ant-select-selection-item {
  line-height: 50px !important;
}

.default-card-border .input-box input {
  padding: 6px;
}

.default-card-border .input-box .ant-select-selector .ant-select-selection-item,
.default-card-border .input-box textarea::placeholder,
.default-card-border .input-box input::placeholder {
  color: #6c6c6c !important;
}

.default-card-border .input-box .ant-picker:hover,
.default-card-border .input-box .ant-select-selector:hover,
.default-card-border .input-box:hover {
  border-color: #181919;
  box-shadow: none !important;
}

.default-card-border .input-box .ant-picker:focus,
.default-card-border .input-box .ant-select-selector:focus,
.default-card-border .input-box:focus {
  box-shadow: none !important;
}

.default-card-border .search-box {
  padding: 5px;
  border-radius: 0.375rem;
  background: var(--color-theme);
}

.default-card-border .search-box .ant-input-search-button {
  background: var(--color-theme);
  color: #fff !important;
  border-color: #99b070 !important;
  background: #a8c37a !important;
  height: 34px;
  width: 34px;
}

.default-card-border .search-box .ant-input-search-button svg {
  font-size: 1rem;
}

.default-card-border .search-box .ant-input-affix-wrapper {
  background: #a8c37a !important;
}

.default-card-border .search-box .ant-input-affix-wrapper {
  border-color: #99b070 !important;
  color: #fff !important;
}

.default-card-border .search-box .ant-input-affix-wrapper input {
  background: transparent !important;
  color: #fff !important;
  font-size: 1rem;
}

.default-card-border .search-box .ant-input-affix-wrapper input::placeholder {
  color: #fff !important;
}

.default-card-border .search-box .anticon svg {
  color: #fff !important;
}

.Mui-checked {
  color: var(--color-theme) !important;
}

.default-card-border .ant-switch.ant-switch-checked {
  background: var(--color-theme) !important;
}

.default-card-border .input-box.input-box-textarea {
  height: 100% !important;
}

.list-card {
  display: flex;
  border: 1px solid #1819192a;
  padding: 10px;
  border-radius: 0.375rem;
}

.list-card p {
  margin-bottom: 0;
}

.list-card .content .box {
  display: flex;
}

.spare-card {
  border: 1px solid #1819192a;
  padding: 10px;
  border-radius: 0.375rem;
  /* border-top: 5px solid var(--color-theme); */
  border-bottom: 5px solid var(--color-theme);
  margin-bottom: 1rem;
}

@media (max-width:568px) {

  .spare-card:last-child {
    margin-bottom: 0;
  }
}

/* .list-card  */