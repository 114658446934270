.customer-list-box{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.customer-list-box .spare-card{
    width: 30%;
}
@media (max-width:568px) {
    .customer-list-box .spare-card{
        width: 100%;
    }   
    .customer-list-box .spare-card .default-btn{
        width: 100%;
    }
}