.top-header-swiper {
  padding-bottom: 2.5rem !important;
}

.top-header-swiper .swiper-pagination-bullet-active {
  background-color: var(--color-theme) !important;
}

.client-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.client-logo img {
  width: 350px;
}

.top-header-box {
  display: flex;
  justify-content: space-between;
}

.top-header-box .top-header-card {
  width: 18.7%;
}

.top-header-card span {
  font-size: 1rem;
  font-weight: 500;
}

.top-badge {
  padding: 5px 8px;
  background: #fff;
  /* color: #000; */
  color: var(--color-theme);
  border-radius: 5px;
  width: fit-content;
  margin-bottom: 15px;
}

.top-badge svg {
  /* color: var(--color-theme); */
}

@media (max-width: 568px) {
  .top-header.top-header-card {
    padding: 10px;
  }

  .top-header-box {
    overflow-y: auto;
  }

  .top-header-box .top-header-card {
    min-width: 230px;
    max-width: fit-content;
    margin-right: 1rem;
  }

  .top-header-box .top-header-card:last-child {
    margin-right: 0;
  }
}
