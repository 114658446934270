.sidenavbar {
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  padding-top: 3.5rem;
  z-index: 6;
  background-color: #fff;
  overflow-y: auto;
}

.sidenavbar ::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

.sidenavbar ::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.sidebar-menuitem {
  font-size: 0.875rem;
  padding: 0.338rem 0.5rem !important;
  font-weight: 400;
  border-radius: 0.375rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 1.5rem;
  border-radius: 0 0 0.375rem 0.375rem;
}

.sidebar-menuitem a {
  height: 36px !important;
  padding: 0 1rem !important;
  border-radius: 0.375rem;
}

.sidebar-menuitem span {
  margin-right: 0;
  color: var(--color-theme);
}
.css-z5rm24 {
  position: relative;
  padding-left: 1.5rem;
}
.css-z5rm24 .css-12w9als::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: #00000080;
  border-radius: 50%;
}

.sidebar-icon {
  font-size: 1.2rem !important;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  text-align: center;
  margin-right: 0.75rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}
.sidebar-icon.fs-6{
  margin-left: 1.5px;
}
.css-honxw6,
.css-jn69v9 {
  width: 8px !important;
  height: 8px !important;
}
/* Custom Tooltip Styling */
.custom-tooltip .ant-tooltip-inner {
  background-color: var(--color-theme) !important; /* Tooltip background */
  color: #fff !important; /* Tooltip text color */
}

.custom-tooltip .ant-tooltip-arrow::before {
  background: var(--color-theme) !important; /* Tooltip arrow color */
}
@media (max-width: 568px) {
  .sidenavbar {
    /* padding-top: 5rem; */
    left: -100%;
    transition: 1s;
  }
  .bodycollapse .sidenavbar {
    left: 0;
  }
  .header-left .dashboard_bar a h3 {
    width: auto;
    font-size: 1rem;
  }
  .margin-left {
    margin-left: 1.1rem;
  }
  .default-card-border .card-heading {
    display: block !important;
  }
  .default-card-border .btn-border {
      align-items: flex-end !important;
      flex-wrap: wrap;
    }
    .default-card-border .btn-border .gap-2{
        flex-wrap: wrap;
    }
    .default-card-border .btn-border .mb-0.ms-3{
        margin-left: 0 !important;
    }
}
