.supervisor-dashboard .job-card-box {
  /* color: #000; */
}

.supervisor-dashboard .jobcard-count-box {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.supervisor-dashboard .jobcard-count-box .jobcard-count-card {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  /* width: 13.6%; */
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--color-theme);
  position: relative;
}

.supervisor-dashboard .jobcard-count-box .jobcard-count-card img {
  position: absolute;
  top: 3px;
  right: 3px;
  /* width: 25px !important; */
  width: 50px !important;
  opacity: 0.7;
}

.bodycollapse .supervisor-dashboard .jobcard-count-box .jobcard-count-card {
  width: 15.8%;
}

.supervisor-dashboard .jobcard-count-box .jobcard-count-card p {
  margin-bottom: 8px;
  font-size: 1.5rem;
  color: #000;
}

.supervisor-dashboard .jobcard-count-box .jobcard-count-card span {
  font-weight: 500;
  color: var(--color-theme);
}

.supervisor-dashboard .job-card-main-box {
  display: flex;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.supervisor-dashboard .job-card-main-box .job-card-box {
  width: 25%;
  margin-right: 1rem;
  border: 1px solid #e3e3e3;
  border-top: 2px solid var(--color-theme);
}

.supervisor-dashboard .tray-shifting-job-card-box .job-card-box {
  width: 22%;
  /* font-weight: 500; */
  color: #000;
}
.supervisor-dashboard .tray-shifting-active-card-box .job-card-box {
  min-width: 23%;
}
.supervisor-dashboard .tray-shifting-active-card-box .job-card-box .box p{
  padding-right: 0 !important;
  width: auto !important;
}
.supervisor-dashboard .tray-shifting-active-card-box .job-card-box .box.border-0,
.supervisor-dashboard .tray-shifting-active-card-box .job-card-box .box.border-0 > .d-flex{
  width: 100%;
}
.supervisor-dashboard .tray-shifting-active-card-box .job-card-box .box{
  gap: 10px;
}
.rejection-dashboard .tray-shifting-job-card-box .job-card-box {
  width: 25%;
  transition: .5s all;
}

.bodycollapse .supervisor-dashboard .tray-shifting-job-card-box .job-card-box {
  width: 25%;
}

.bodycollapse .rejection-dashboard .tray-shifting-job-card-box .job-card-box {
  width: 30%;
}

.supervisor-dashboard .tray-shifting-job-card-box .job-card-box .box:last-child p {
  padding: 5px 0;
  padding-left: 5px;
}

.supervisor-dashboard .job-card-main-box .job-card-box .box img {
  width: 40px;
  height: 40px;
}

.supervisor-dashboard .gas-bank-card-box .job-card-box {
  width: 25%;
}

.supervisor-dashboard .gas-bank-card-box .job-card-box .box img {
  border-radius: 0;
  width: 20px;
  border: none;
  margin-right: 2px;
  height: auto;
}

.supervisor-dashboard .gas-bank-card-box .job-card-box .box:first-child img {
  width: 25px;
  transform: translateX(-3px);
}

.supervisor-dashboard .gas-bank-card-box .job-card-box .box:nth-child(2) img {
  width: 20px;
  margin-right: 5px;
}

.supervisor-dashboard .job-card-main-box .job-card-box .box {
  font-size: 0.9rem;
}

.supervisor-filter-drawer {
  border-radius: 10px 10px 0 0;
}

.supervisor-filter-drawer .ant-drawer-header {
  background: var(--color-theme);
}

.supervisor-filter-drawer .ant-drawer-header .ant-drawer-header-title {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.supervisor-filter-drawer .ant-drawer-header .ant-drawer-header-title .ant-drawer-close,
.supervisor-filter-drawer .ant-drawer-header .ant-drawer-header-title .ant-drawer-title {
  color: #fff !important;
}

.supervisor-filter-drawer .ant-space-item {
  width: 100%;
}

.supervisor-filter-drawer .ant-radio-group,
.supervisor-filter-drawer .ant-space-vertical {
  width: 100%;
  align-items: flex-start;
}

.supervisor-filter-drawer .ant-space-item label {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.supervisor-filter-drawer .ant-space-item label::after {
  display: none;
}

.supervisor-filter-drawer .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background: var(--color-theme) !important;
  border-color: var(--color-theme) !important;
}


.supervisor-dashboard .spare-card-list .job-card-box {
  position: relative;
}

.supervisor-dashboard .spare-card-list .status-box,
.status-box-dispatched {
  position: absolute;
  right: 5px;
  top: 5px;
}

.supervisor-dashboard .spare-card-list .status-box p {
  border-radius: 5px;
  display: inline-block;
  padding: 2px 6px !important;
  background: #ffc107;
  color: #fff;
  font-size: 0.6rem;
}

.status-box-dispatched p {
  border-radius: 5px;
  display: inline-block;
  padding: 2px 6px !important;
  background: #0dcaf0;
  color: #fff;
  font-size: 0.6rem;
}

.supervisor-dashboard .job-card-main-box .job-card-box .box p {
  font-size: .8rem;
  padding: 5px 0;
  padding-left: 5px;
  width: 90%;
}

.supervisor-dashboard .job-card-main-box {
  align-items: stretch;
}

.supervisor-dashboard .job-card-main-box .job-card-box {
  height: auto;
}

.supervisor-dashboard .job-card-main-box .job-card-box .box:first-child p {
  padding-right: 3rem;
}

@media (max-width: 568px) {
  .supervisor-dashboard .job-card-main-box .job-card-box .box .card-number {
    font-size: 1rem;
    padding: 0;
  }

  .supervisor-dashboard .job-card-main-box .job-card-box .box span {
    color: #000;
    width: 55%;
    font-size: 0.7rem;
  }

  .supervisor-dashboard .jobcard-count-box .jobcard-count-card img {
    width: 25px !important;
    opacity: .5;
  }

  .supervisor-dashboard .jobcard-count-box .jobcard-count-card {
    width: 22%;
    line-height: 1;
    aspect-ratio: 1/1;
  }

  .supervisor-dashboard .job-card-main-box .job-card-box .box p {
    font-size: 0.7rem;
    padding: 5px 0;
  }

  .supervisor-dashboard .jobcard-count-box .jobcard-count-card p {
    font-size: 1.3rem;
    /* margin-bottom: 0; */
  }

  .supervisor-dashboard .jobcard-count-box .jobcard-count-card span {
    font-size: 0.55rem;
    font-weight: bold;
    color: var(--color-theme);
  }

  .supervisor-dashboard .job-card-box {
    min-width: 80%;
  }

  .supervisor-dashboard .spare-card-list .job-card-box {
    min-width: 80%;
  }

  .supervisor-dashboard .spare-card-list .box {
    padding: 5px 0;
    font-size: 0.9rem;
    gap: 10px;
  }

  .supervisor-dashboard .spare-card-list .job-card-box svg {
    font-size: 1.1rem;
  }

  .supervisor-dashboard .card-heading {
    display: flex !important;
  }

  .supervisor-dashboard .card-heading h4 {
    font-size: 1.1rem;
  }

  .supervisor-dashboard .card-heading span {
    width: 30px;
    height: 30px;
    background: var(--color-theme);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.2rem;
  }

  .spare-card-list-page .spare-card-list {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .spare-card-list-page .spare-card-list .job-card-box {
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  /* .spare-card-list-page .spare-card-list .job-card-box .status-box {
    top: 0;
  } */
  /* .spare-card-list-page .spare-card-list .job-card-box .status-box p {
    font-size: 0.5rem;
  } */
  /* .spare-card-list-page .spare-card-list .job-card-box:nth-child(2n) {
    margin-right: 0;
  } */
  .spare-card-list-page .spare-card-list .job-card-box:last-child {
    margin-bottom: 0;
  }
  .supervisor-dashboard .tray-shifting-active-card-box .job-card-box {
    min-width: 80%;
  }
}