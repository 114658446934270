.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0rem;
  background: var(--color-theme);
  z-index: 999;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: all 0.2s ease;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  /* height: 4.5rem; */
  display: flex;
  align-items: center;
}

* {
  outline: none;
  padding: 0;
}

.header .header-content {
  transition: all 0.5s;
}

.header .header-content {
  /* height: 4.5rem; */
  align-items: center;
  display: flex;
  left: 16.2rem;
  position: unset;
  top: 0;
  border-top: none;
  padding: 0;
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -4rem;
  text-align: center;
  top: 46%;
  transform: translateY(-43%);
  z-index: 9999;
  font-size: 1.4rem;
  background: transparent;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
}

.header-left .dashboard_bar,
.header-left .dashboard_bar a {
  /* font-size: 1.563rem; */
  font-weight: 600;
  color: #fff;
  border: none;
}

.header-left .dashboard_bar a {
  /* display: flex;
  flex-direction: column; */
}

.header-left .dashboard_bar a h3 {
  width: 400px;
  margin-bottom: 0;
  /* font-size: 1rem; */
}

.header-left .dashboard_bar a p {
  margin-bottom: 0;
}

.header-left .dashboard_bar img {
  width: 240px;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.header-right {
  height: 100%;
  align-items: center;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.header-right .nav-item {
  height: 100%;
  align-items: center;
}

.all {
  display: none !important;
}

li {
  list-style: none;
}

.header-right .notification_dropdown .nav-link {
  background-color: #aa7ea5;
  border-color: #aa7ea5;
}

.nav-item.notification_dropdown a.nav-link {
  position: relative;
}

.header-right .notification_dropdown .nav-link {
  margin-right: 0.625rem;
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  color: var(--primary);
  border-radius: 0.375rem;
  background-color: #ffffff;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 1;
  margin-right: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar-link,
.nav-bar-link .btn {
  font-size: 1rem;
  color: var(--color-theme);
  border-radius: 0.3rem;
  /* background-color: #aa7ea5; */
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.nav-bar-link .btn::after {
  display: none !important;
}

.btn-check:checked+.nav-bar-link.btn,
.nav-bar-link .btn.active,
.nav-bar-link .btn.show,
.nav-bar-link .btn:first-child:active,
:not(.btn-check)+.nav-bar-link.btn:active {
  background-color: #fff !important;
  color: var(--color-theme) !important;
}

.btn-check:checked+.nav-bar-link.btn:focus-visible,
.nav-bar-link .btn.active:focus-visible,
.nav-bar-link .btn.show:focus-visible,
.nav-bar-link .btn:first-child:active:focus-visible,
:not(.btn-check)+.nav-bar-link.btn:active:focus-visible,
.nav-bar-link .btn:focus-visible {
  box-shadow: none !important;
}

.nav-bar-link .dropdown-menu {
  background-color: #fff;
  padding: 0.5rem;
  max-width: 300px !important;
  width: 250px;
  border-radius: 5px !important;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: transparent !important;
}
.nav-bar-link .form-control {
  border-radius: 5px !important;
}

.nav-bar-link:hover {
  cursor: pointer;
}

.nav-bar-link:first-child .dropdown-menu.show {
  transform: translate3d(-80%, 42.4px, 0px) !important;
}

.profile-img {
  /* width: 40px;
  height: 40px; */
  object-fit: contain;
  /* border-radius: 0.3rem; */
}

.header-right .btn-primary:hover {
  /* background-color: var(--color-theme) !important; */
  background-color: #fff !important;
  color: var(--color-theme) !important;
}

.offcanvas {
  background-color: #fff !important;
}

.offcanvas-header {
  background-color: var(--color-theme);
  color: #fff;
}

.btn-close {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  opacity: 1;
}

.btn-close:focus {
  box-shadow: none !important;
}

.setting-offcanvas h6 {
  color: #000;
  margin-bottom: 10px;
}

.setting-offcanvas .ant-upload-select {
  width: 100% !important;
}

@media (max-width: 568px) {
  .header {
    width: 100vw;
    padding: 0 1rem;
  }
}

@media (max-width: 380px) {
  .header-left .dashboard_bar a h3 {
    font-size: 1rem;
  }

  .header-left .dashboard_bar a p {
    font-size: 0.8rem;
  }

  .dashboard_bar {
    margin-right: 1rem !important;
  }
}