.main-img {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../assets/img/login-banner.jpeg);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.card-align {
  height: 100vh;
  display: flex;
  align-items: center;
}
.card-align .card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.048);
  color: #fff !important;
}

.card-align .card input {
  background: transparent !important;
  color: #fff;
  border-color: #f3f3f3c4 !important;
}
.form-control:focus {
  border-color: #f3f3f3c4 !important;
  outline: none !important;
  box-shadow: none !important;
}
.card-align .card button {
  background-color: transparent;
  /* border: none !important; */
  padding: 7px 20px;
  color: #fff;
  border: 1px solid #f3f3f3c4;
}
.card-align .card input::placeholder {
  color: #fff;
}
.logo-content {
  position: absolute;
  top: 10px;
  top: 150px;
  left: 40px;
}
.logo-parent {
  position: absolute;
  bottom: 8px;
  height: 80px !important;
  width: auto;
}
.logo-content h1 {
  font-size: 60px;
}
.login-card {
  right: 40px !important;
}
.copyright-content {
  position: absolute;
  bottom: 0;
  right: 40px;
  color: white;
}
.becofy-copyright,
.becofy-copyright:hover {
  color: white !important;
  cursor: pointer;
}
.usericon {
  font-size: 75px;
  height: 70px;
}
.icon-size {
  font-size: 18px;
}
.login-card-shadow {
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63) !important;
}
.eye-parent {
  border-left-style: none !important;
}
.closebtn-brandmanagment {
  text-align: end;
}
.login-modal-icon svg{
  color: var(--color-theme);
}
.logout-modal .modal-content{
  background: #fff !important;
}
.logout-modal .modal-content .btn-close{
  filter: inherit;
    -webkit-filter: inherit;
}
@media (max-width: 800px) {
  .header-menu-responsive {
    font-size: 12px !important;
  }
  .main-img {

    background-position-x: -900px;
  }
}
@media (max-width: 992px) {
  .mainbreadcrumb-responsive {
    margin-top: 30px;
  }
}
@media (max-width: 992.98px) {
  .navbar-brand-box .logo-light span.logo-sm {
    display: inline-block !important;
  }
  .logo-sm {
    width: 60px !important;
    height: 60px !important;
  }
  .logo-top-smallview {
    width: 50px !important;
    height: 50px !important;
  }
}
@media (max-width: 1480px) {
  .sales-summery-responsive {
    overflow-x: auto;
  }
}
@media (max-width: 1140px) and (min-width: 870px) {
  .lastestcustomer-table-responsive {
    overflow-x: auto;
  }
  .customer-table-responsive {
    width: 120vw;
  }
}
@media (max-width: 870px) {
  .lastestcustomer-table-responsive {
    overflow-x: auto;
  }
  .customer-table-responsive {
    width: 150vw;
  }
}
@media (max-width: 1034px) {
  .logo-content h1 {
    font-size: 40px;
  }
  .logo-content h3 {
    font-size: 20px;
  }
  .login-card {
    right: 10px !important;
  }
  .copyright-content {
    position: absolute;
    bottom: 0;
    right: 18px;
    color: white;
  }
  .logo-content {
    position: absolute;
    top: 10px;
    top: 150px;
    left: 10px;
  }
  .logo-parent {
    position: absolute;
    bottom: 0;
    height: 125px;
    left: 0px;
    width: auto;
  }
}
.fadeIn {
  -webkit-animation: fadeIn 200ms ease-in-out;
  animation: fadeIn 200ms ease-in-out;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
.stickyElx {
  transition: all 0.5s;
  z-index: 1;
}
@media (max-width: 568px) {
  .inner-footer,
  .login-footer {
    text-align: center;
    flex-direction: column-reverse;
    padding: 10px;
  }
}
/* ***********Dashboard********** */
.product-icon-font {
  font-size: 30px !important;
}

.topnav {
  margin-top: 60px !important;
}
/* *********Breadcrumb***** */
.last-login-font {
  font-size: 13px !important;
  font-weight: 600;
  color: #000000 !important;
}
.tablebg {
  background: #eff2f7 !important;
}
.footer-content {
  font-size: 13px !important;
}
.footer-content a,
.footer-content a:hover {
  color: #495057;
}
.whatsapp-font {
  font-size: 34px !important;
  color: white;
}
.whatsapp-parent {
  background: #008000b0;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.support-parent {
  background: #1a1819;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.suppport-font {
  color: #1a1819;
}
.search-icon-mdtable {
  rotate: 80deg;
  font-size: 20px;
}
.newcustomer-mdtablecol {
  text-align: left !important;
}
.latest-order-card-size-comon {
  height: 410px;
}
.summery-size-comon {
  height: 290px;
}
.latestreview-mdbtable .dataTables_length,
.latestreview-mdbtable .dataTables_paginate {
  display: none !important;
}
.latestreview-mdbtable .dataTables_info {
  display: none;
}
/* ***************Brand List************** */
.fadePage {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  bottom: 0;
  height: 100vh;
  display: block;
}
.categoryCard.focuscard,
.focuscard {
  z-index: 99999;
  background: #fff;
  position: relative;
}
.focusinput {
  height: 56px !important;
}
.card-row-bottom {
  border-bottom: none !important;
}
.cardpadding-brand {
  padding-top: 32px !important;
}
.categoryCard.focuscardvertical {
  z-index: 99999;
  background: #fff;
  position: relative;
  padding-top: 5px !important;
}
/* *******Vertical master component card************* */
.vertical-mastercard-border {
  border-bottom: none !important;
}
.brand-img-vertical-master {
  width: 100% !important;
  position: relative;
  height: 130px !important;
  overflow: hidden;
}
.drawer {
  /* top: 0; */
  top: 60px !important;
  padding-bottom: 0 !important;
  padding-top: 78px;
  height: 92vh !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  justify-content: left !important;
}
.dragdrop-side {
  position: fixed;
  top: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
}
.sequentialize-parent {
  height: 50px !important;
}
.sequentialize-img {
  object-fit: fill;
  border-radius: 5%;
  width: 50px !important;
  height: 50px !important;
}
.sequence-drwa-icons {
  width: 50px;
}
.sequence-draw-title {
  line-height: 20px;
  text-align: justify;
}
.sequence-save-btn {
  width: 100%;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px !important;
  height: 10% !important;
  /* box-shadow: 0px 0px 4px 0px #80808075 !important; */
}
.serialize-side-drawer {
  overflow-y: scroll;
  padding-bottom: 30% !important;
}
.vertical-hide-close {
  opacity: 0 !important;
}
.serialize-side-drawer::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
}
.serialize-side-drawer:-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.serialize-side-drawer::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}
.container-content {
  border: none !important;
}
.customer-download-btn {
  float: right;
  margin-top: 8px !important;
  background: white;
  margin-right: 25px;
}
.vertical-sequentialize-btn:hover {
  color: #808080 !important;
  float: right;
  background: white;
  margin-right: 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid white !important;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.vertical-sequentialize-btn {
  color: #808080 !important;
  float: right;
  background: white !important;
  margin-right: 20px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid white !important;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.categorybtnposition {
  float: right;
  margin-top: -5px;
  background: white;
}
.category-Add-btn,
.category-Add-btn:hover {
  color: #808080 !important;
  float: right;
  background: white !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid white !important;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* ********Category managment ************ */
.categorymanagment-img-style {
  width: 100% !important;
  height: 300px !important;
  border-radius: 5%;
}
.category-description-empty {
  text-align: center;
}
.category-description-empty img {
  width: 50px;
  height: 50px;
}
/* ****** Subcategory card component ***** */
.subcategory-card-content span {
  text-align: justify;
  font-size: 15px !important;
  color: #74788d !important;
  line-height: 24px !important;
}
.card-content-overflow-subcategory {
  height: 170px !important;
  overflow-y: auto !important;
}
.card-content-overflow-subcategory::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #74788d;
}
.card-content-overflow-subcategory:-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}
.card-content-overflow-subcategory::-webkit-scrollbar {
  width: 7px;
  background-color: white;
}
.card-samesize-barandmanagment {
  height: 330px;
}
.card-focus {
  height: 340px;
}
.card-samesize-verticalmaster {
  height: 327px !important;
}
.card-description-brand-overflow {
  height: 40px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.vertical-card-focos {
  height: 330px !important;
}
.sequ-btn-tooltip {
  text-align: end !important;
}
.card-description-vert-overflow {
  height: 38px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
/* ************** Item management ************** */
@media (max-width: 1389px) and (min-width: 1200px) {
  .refresh-item-responsive-btn {
    margin-top: 10px;
  }
}
/* **********************View customar****************** */
.view-customer-bg {
  background: #777f80 !important;
}
.viewcustomer-ordersummery-font {
  font-size: 20px;
  font-weight: 300;
}
.borderview-customer {
  border: 1px solid #f6f6f6 !important;
}
.viewcustomer-top-card-size {
  height: 370px !important;
}
.view-customer-total-order {
  margin: 10px 0px !important;
}
.customerhistory-icons {
  width: 75px;
}
.shopping-cart-font {
  font-size: 19px !important;
}
/* ****** Add New items ****** */
.addnew-item-chips {
  background: transparent !important;
  border: 1px #000000;
  border-radius: 5px;
}
/* ******Footer***** */
.footer-bottom-border {
  border-bottom: 8px solid rgb(26, 24, 25);
  height: 73px !important;
}
/* ***********Navbar************* */
/* .navbar-menu-bg li,.navbar-menu-bg li a ,.navbar-menu-bg{
  background: rgb(26, 24, 25);
  border-radius: 5px;
  padding: 2px;
  color: white !important;
} */
/* ************Attributes********* */
.assign-attribute-font {
  font-size: 13px !important;
  margin-top: 12px;
}
/* .attribute-cards{
  border: 1px solid #f8f8fb;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 20px;
    padding-bottom: 5px;
    margin-top: 10px;
} */
.attribute-card {
  border: 1px solid #f8f8fb !important;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;
}
.add-attribute-main-shadow {
  box-shadow: none !important;
}
.add-attribute-status {
  margin-right: -70px !important;
}
.attribut-list-border {
  border: none !important;
  box-shadow: none !important;
}
.item-varient-select-card {
  height: 300px;
}
.page-itm-side-draw .page-content {
  padding: 0px !important;
  margin: 0px !important;
}
.addstockitem-font {
  color: #000000 !important;
}
.hidebread .mainbreadcrumb-responsive {
  display: none !important;
}
.login-footer {
  position: absolute;
  bottom: 0;
  left: 12px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.048);
  color: #fff !important;
  padding: 1rem;
}
