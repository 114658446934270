@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

:root {
  /* --color-theme: #94618e; */
  --color-theme: #9fb873;
  --bs-font-sans-serif: "Poppins", sans-serif;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
  --bs-body-font-family: "Poppins", sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #a098ae;
  --bs-body-bg: #e6ebee;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eeeeee;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.625rem;
  --bs-border-radius-sm: 0.625rem;
  --bs-border-radius-lg: 0.625rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 0.625rem;
  --bs-link-color: #4d44b5;
  --bs-link-hover-color: #3e3691;
  --bs-code-color: #eb62d0;
  --bs-highlight-bg: #fffee2;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.margin-left {
  margin-left: 6rem;
  margin-right: 1.1rem;
  transition: 0.5s;
}

.bodycollapse .margin-left {
  margin-left: 16.7rem;
}

#main-wrapper.show {
  opacity: 1;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  position: relative;
  background-color: #e6ebee;
}

.css-16jesut:hover .css-uocmrg {
  visibility: visible !important;
}

.default-card {
  /* margin-bottom: 1.875rem; */
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  padding: 1rem;
  border-radius: 0.375rem;
  height: 100%;
}

.default-card h6 {
  color: #000;
  margin-bottom: 1rem;
}

.top-header {
  /* margin-bottom: 1.875rem; */
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
  padding: 1rem;
  border-radius: 0.375rem;
  /* height: calc(100% - 30px); */
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap; */
}

.top-header .top-header-icon {
  height: 3.75rem;
  width: 3.75rem;
  line-height: 3.4rem;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 1.5rem;
}

.top-header-card {
  display: flex;
  /* align-items: center; */
  /* width: 25%; */
  /* justify-content: center; */
}

/* .top-header-card:first-child{
  justify-content: flex-start;
} */
.top-header p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  /* color: #303972; */
  color: #212121;
}

.top-header-card .top-header-icon {
  background: #9aa581;
}

.top-header-card .top-header-icon img {
  width: 60%;
}

/* .top-header-card:nth-child(1) .top-header-icon {
  background-color: var(--color-theme);
}

.top-header-card:nth-child(2) .top-header-icon {
  background-color: #fb7d5b;
}
.top-header-card:nth-child(3) .top-header-icon {
  background-color: #fcc43e;
}
.top-header-card:nth-child(4) .top-header-icon {
  background-color: #303972;
}
.top-header-card:nth-child(5) .top-header-icon {
  background-color: #39895c;
} */
.react-calendar {
  width: 100% !important;
  border: none !important;
}

.table> :not(caption)>*>* {
  background-color: #fff !important;
  border: none !important;
}

.footer a {
  text-decoration: none;
  color: #000;
}

.dataTable th,
.align-middle th {
  color: #000000a3;
  font-weight: 600 !important;
}

.card-heading {
  border-bottom: 1px solid #1819192a;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  color: #000;
}

.btn-border {
  border-top: 1px solid #1819192a;
}

.color-theme {
  color: var(--color-theme) !important;
}

.default-btn {
  padding: 5px 15px;
  background-color: var(--color-theme);
  color: #fff !important;
  border-radius: 0.375rem;
  transition: 0.3s;
  font-weight: bold;
  cursor: pointer;
}

.default-btn-disabled {
  padding: 5px 15px;
  background-color: #9fb8735e;
  color: #fff !important;
  border-radius: 0.375rem;
  transition: 0.3s;
  font-weight: bold;
  cursor: pointer;
}

.default-btn:hover {
  box-shadow: 0 0 10px var(--color-theme) 86;
  /* padding: 10px 20px; */
  background-color: var(--color-theme);
  color: #fff !important;
  border-radius: 0.375rem;
  transition: 0.3s;
  font-weight: bold;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active,
.btn:focus-visible {
  background-color: var(--color-theme) !important;
  box-shadow: none !important;
}

.product-management-cards-box {
  display: flex;
  justify-content: space-between;
}

.product-management-cards-box .product-management-card {
  width: fit-content;
  border-right: 1px solid var(--color-theme);
  padding: 0 1rem;
  text-align: center;
}

.product-management-cards-box .product-management-card span {
  font-size: 0.8rem;
  font-weight: 500;
  /* text-align: center; */
  border-bottom: 1px solid #e3e3e3;
  display: inline-block;
  width: 100%;
  padding-bottom: 5px;
}

.product-management-cards-box .product-management-card p {
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 10px;
}

.product-management-cards-box .product-management-card:last-child {
  border-right: 0;
}

.blank-section {
  min-height: 92vh;
}

.nav-bar-link-close {
  display: none !important;
}

.bodycollapse .nav-bar-link-open {
  display: none !important;
}

.bodycollapse .nav-bar-link-close {
  display: flex !important;
  font-size: 1.2rem !important;
}

.anticon {
  vertical-align: 0 !important;
}

.tab-filter .nav-pills {
  border: 2px solid var(--color-theme) !important;
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
}

.tab-filter .nav-link,
.tab-filter .nav-item {
  margin-right: 0px;
  font-size: 16px;
}

.nav-pills .nav-link {
  color: var(--color-theme) !important;
  border-radius: 2px;
}

.nav-pills .nav-link.active {
  background-color: var(--color-theme);
  border-radius: 2px;
  color: #fff !important;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  /* padding: 10px; */
  border-radius: 5px;
}

.pagination-container .top-headings {
  border-radius: 5px 5px 0 0;
  border-top: 4px solid var(--color-theme) !important;
  top: 55px;
}

.pagination-container .top-headings ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-weight: 500;
  background-color: #f3f3f3;
  padding: 0;
}

.pagination-container .top-headings ul li {
  border-right: 1px solid #000;
  padding: 1rem;
  text-align: center;
  width: 18%;
}

.pagination-container .top-headings ul li:first-child {
  width: fit-content;
}

.pagination-container .top-headings ul li:last-child {
  border: 0;
}

.pagination-container .items-container {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 20px;
}

.pagination-container .item {
  background-color: #f0f0f0;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination-buttons {
  display: flex;
  gap: 5px;
}

.page-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.page-button.active {
  background-color: #0056b3;
}

.page-button:hover {
  background-color: #0056b3;
}

.default-card-border .spare-request-card {
  padding: 10px;
  border-radius: 5px;
  height: 100%;
}

.default-card-border .spare-request-card h6 {
  /* font-size: .9rem; */
  /* min-height: 40px; */
}

.default-card-border .spare-request-card img {
  aspect-ratio: 1/1;
  object-fit: contain;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

.default-card-border .spare-request-card .input-box {
  height: auto !important;
}

.default-card-border .spare-request-card .input-box input {
  padding: 4px !important;
}

.view-added-spares-modal .offcanvas-body {
  position: relative;
  min-height: 90vh !important;
}

.view-added-spares-modal .send-spare-off-btn {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 1rem;
  right: 0;
  background: #fff;
}

.view-added-spares-modal ul {
  padding: 0;
}

.view-added-spares-modal ul li {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1rem;
}

.view-added-spares-modal ul li img {
  border-radius: 2px;
}

.view-added-spares-modal .offcanvas-body {
  padding-bottom: 5rem;
}

.view-added-spares-modal ul button {
  border-radius: 5px;
  /* width: 25px;
  height: 25px; */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-self: flex-start; */
  background: transparent !important;
  color: #ff5f5f;
  border: none !important;
  font-size: 1.5rem !important;
}

.view-added-spares-modal ul button:hover {
  color: #ff0000 !important;
}

.spare-card-list-page .job-card-box {
  min-width: 25%;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: var(--color-theme) !important;
}

.ant-select-focused .ant-select-selector {
  border-color: var(--color-theme) !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background: #9fb8731d !important;
}

.rejection-box .card-heading .ant-select {
  width: 180px;
}

.store-stock-select {
  min-width: 150px;
}

.col-12.sticky-bottom.bg-white {
  z-index: 99 !important;
}

.table-lay-fix table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
  white-space: nowrap;
}

.table-lay-fix table th,
.table-lay-fix table td {
  padding: 5px !important;
  font-size: 0.8rem !important;
}

.spare-request-color {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }
}

@media (max-width: 992px) {
  .tab-filter {
    overflow-x: auto;
    padding: 0;
  }

  /* .tab-filter .nav-pills {
    width: 280% !important;
    flex-wrap: nowrap;
  } */
  .overflow-x-auto.store-warehouse-readygoods {
    width: 100%;
  }

  .choose-warehouse {
    justify-content: space-between;
    gap: 20px 10px !important;
    width: 100%;
  }

  .overflow-x-auto.store-warehouse-readygoods .nav-pills {
    justify-content: space-between;
  }

  .choose-warehouse .store-stock-select {
    width: 53%;
  }

  .choose-warehouse .stock-down-btn {
    width: 40%;
  }

  .choose-warehouse .stock-down-btn button {
    width: 100%;
  }
}

@media (max-width: 568px) {
  .bodycollapse .margin-left {
    filter: blur(100px);
    overflow: hidden;
    height: 50vh !important;
  }

  .product-management-cards-box {
    overflow-x: auto;
  }

  .product-management-cards-box .product-management-card {
    min-width: 200px;
  }

  .default-card-border .search-box {
    width: 100% !important;
    margin-top: 10px;
  }

  .default-card .card-heading h4 {
    font-size: 1.1rem;
  }

  .default-card-border .spare-request-card h6 {
    min-height: auto;
  }

  .default-card-border .spare-request-card button {
    font-size: 0.9rem !important;
    padding: 5px !important;
    width: 100%;
  }

  .go-back-btn {
    font-size: 1rem;
  }

  .css-16jesut:hover .css-uocmrg {
    display: none !important;
  }

  .spare-request-table {
    overflow-x: auto;
  }

  .spare-request-table table {
    width: 300%;
  }

  .warehouse-request-table table {
    width: 250%;
  }

  .dispatched-order-table table {
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
    white-space: nowrap;
  }

  .dispatched-order-table table .default-btn {
    padding: 5px 8px !important;
  }

  /* .dispatched-order-table table th,
  .dispatched-order-table table td {
    padding: 5px !important;
    font-size: 0.8rem;
  } */

  .stock-down-btn {
    /* width: 100%; */
  }

  /* .mobile-stock-card-box {
    gap: 0 1rem;
  }
  .mobile-stock-card-box .job-card-box {
    min-width: 47% !important;
    padding: 10px !important;
  }
  .mobile-stock-card-box .job-card-box .box{
    flex-direction: column;
    gap: 5px !important;
  }
  .mobile-stock-card-box .job-card-box .box p{
    padding: 0 !important;
  } */
}

.ant-btn-primary {
  background-color: var(--color-theme) !important;
  box-shadow: none !important;
}

.ant-btn-default:hover {
  border-color: var(--color-theme) !important;
  color: var(--color-theme) !important;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: var(--color-theme) !important;
}

.text-theme {
  color: var(--color-theme) !important;
}

.jobcard-color-span {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.fixed-view-job-card-btn {
  position: sticky;
  bottom: 0;
}

.react-confirm-alert-overlay {
  background: #0000007d !important;
}

.react-confirm-alert-body {
  box-shadow: none !important;
  text-align: center;
}

.react-confirm-alert-button-group>button {
  background-color: var(--color-theme) !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.dispatched-order-offcanvas img {
  height: 200px;
  object-fit: contain;
}

.download-pdf-modal .download-pdf-modal-header .date-time-badge {
  display: flex;
  gap: 8px;
  align-items: center;
  background: var(--color-theme);
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
}

.download-pdf-modal .download-pdf-modal-header .date-time-badge p {
  display: flex;
  gap: 5px;
  align-items: center;
}

.download-pdf-modal .download-pdf-modal-header .date-time-badge p svg {
  margin-top: -1px;
}

.custom-alert-overlay {
  z-index: 9999 !important;
}

.border-theme {
  border-color: var(--color-theme) !important;
}

.job-card-save-prev p {
  border-radius: 5px;
  color: #000 !important;
}

.job-card-save-prev p span {
  font-weight: 600;
}

.w-20 {
  width: 10%;
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .fixed-view-job-card-btn span {
    width: 100%;
  }

  .w-20 {
    width: 20%;
  }

  .download-pdf-modal .download-pdf-modal-header {
    flex-wrap: wrap;
    /* gap: 10px; */
  }

  .download-pdf-modal .download-pdf-modal-header h4 {
    font-size: 1.2rem;
  }

  .download-pdf-modal .download-pdf-modal-header .date-time-badge {
    /* font-size: .8rem; */
    /* margin-top: 0.6rem;
    background: transparent;
    color: #000;
    width: 100%;
    justify-content: space-between;
    padding: 0; */
  }

  .download-pdf-modal h6 {
    font-size: 0.9rem;
  }

  /* .download-pdf-modal .download-pdf-modal-header .date-time-badge svg {
    color: var(--color-theme);
  } */

  .store-stcok-mcard .stcok-bottom-fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e3e3e3;
  }

  .store-stcok-mcard .stcok-bottom-fields p {
    width: 50%;
    padding: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .store-stcok-mcard .stcok-bottom-fields p svg {
    color: var(--color-theme);
  }

  .store-stcok-mcard .stcok-bottom-fields p:first-child {
    border-right: 1px solid #e3e3e3;
  }

  .spare-request-form-page .default-card-border .card-heading {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .spare-request-form-page .default-card-border .card-heading>div {
    margin-left: auto;
    margin-top: 5px;
  }

  .spare-request-form-page .btn-border button {
    font-size: 0.75rem !important;
    width: 47%;
    padding: 5px !important;
  }
}

@media (max-width: 420px) {
  .warehouse-table {
    /* min-width: 1000px; */
    background: #fff;
  }

  .warehouse-table table {
    transform: scale(1);
    /* Adjust scale to fit */
    transform-origin: top left;
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
    white-space: nowrap;
  }

  .warehouse-table table th,
  .warehouse-table table td {
    font-size: 10px !important;
    /* Smaller text */
    padding: 8px !important;
    /* Less spacing */
  }

  /* .blank-section .store-warehouse-readygoods .nav-pills .nav-link.active {
    display: flex;
    align-items: center;
  }

  .blank-section .store-warehouse-readygoods .tab-filter .nav-link,
  .tab-filter .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .blank-section .store-warehouse-readygoods .nav-pills .nav-link {
    font-size: 12px !important;
  }

  /*
  .blank-section .tab-filter {
    padding: 0 5px !important;
  } */

  .blank-section .store-warehouse-readygoods {
    width: 100% !important;
  }

  .blank-section .choose-warehouse {
    width: 100% !important;
    justify-content: space-between;
  }

  .blank-section :where(.css-dev-only-do-not-override-txh9fw).ant-select-single {
    width: 45% !important;
  }

  .blank-section :where(.css-dev-only-do-not-override-txh9fw).ant-select-single.w-sm-100 {
    width: 100% !important;
  }

  .store-stock-select.warehouse-select {
    width: 100% !important;
  }

  .store-stock-select.warehouse-select.w-sm-50 {
    width: 50% !important;
  }

  .blank-section {
    min-height: 88vh;
  }

  .blank-section .store-warehouse-readygoods .nav {
    justify-content: space-between;
  }

  .mobile-dispatched-card .box .default-btn {
    width: auto !important;
    display: flex;
    align-items: center;
  }

  .mobile-dispatched-card .box .default-btn svg {
    color: #fff !important;
    margin-bottom: 0 !important;
  }

  .choose-warehouse .stock-down-btn {
    width: auto;
    margin-left: auto;
  }

  .choose-warehouse .store-stock-select {
    min-width: 145px !important;
  }
}

@media (max-width: 360px) {
  /* .blank-section .store-warehouse-readygoods .tab-filter .nav-link,
  .tab-filter .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blank-section .store-warehouse-readygoods .nav-pills .nav-link.active {
    display: flex;
    align-items: center;
  } */

  .blank-section .store-warehouse-readygoods .nav-pills .nav-link {
    font-size: 12px !important;
  }

  .blank-section .store-warehouse-readygoods {
    width: 100% !important;
  }

  .blank-section .choose-warehouse {
    width: 100% !important;
    justify-content: space-between;
  }

  .blank-section :where(.css-dev-only-do-not-override-txh9fw).ant-select-single {
    width: 45% !important;
  }

  .blank-section {
    min-height: 86vh !important;
  }

  .blank-section .store-warehouse-readygoods .nav {
    justify-content: space-between;
  }


}