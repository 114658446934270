.job-card-page .job-card-box {
  position: relative;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.375rem;
  height: 100%;
  border-top: 5px solid var(--color-theme);
  cursor: pointer;
}

.job-card-page .job-card-box .right-arrow {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 30px;
  height: 30px;
  background-color: #f3f3f3;
  color: var(--color-theme);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-card-page .job-card-box .right-arrow svg {
  font-size: 1rem !important;
}

.job-card-page .job-card-box .box .card-number {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-theme);
  padding: 0;
}

.job-card-page .job-card-box .box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
}

.job-card-page .job-card-box .box img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 1px solid #f3f3f3;
  margin-right: 10px;
}

.job-card-page .job-card-box .box p {
  font-size: 1.1rem;
  margin-bottom: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.job-card-page .job-card-box .box:last-child {
  border-bottom: none;
}

.job-card-page .job-card-box .box:last-child p {
  margin-bottom: 0;
  padding-bottom: 0;
}

.job-card-page .job-card-box .box svg {
  color: var(--color-theme);
  font-size: 1.3rem;
  margin-right: 5px;
}

.job-card-stage-page .top-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 2rem;
}

.job-card-stage-page .top-bar p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--color-theme);
}

.job-card-stage-page .top-bar .countdown {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  gap: 10px;
}

.job-card-stage-page .top-bar .countdown .ant-statistic-content {
  font-size: 1rem !important;
}

.job-card-stage-page .ant-steps-item-content {
  cursor: pointer;
}

.job-card-stage-page
  .ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: var(--color-theme) !important;
}

.job-card-stage-page .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
}

.job-card-stage-page .ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--color-theme) !important;
  border-color: var(--color-theme) !important;
}

.job-card-stage-page
  .ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--color-theme);
}

.job-card-stage-page .ant-statistic-title {
  margin-bottom: 0;
  font-weight: 400;
}

.job-card-stage-page .btn:disabled {
  background-color: #e3e3e3 !important;
  cursor: not-allowed !important;
  color: var(--black-color) !important;
  pointer-events: all !important;
  opacity: 1 !important;
}

/* .top-bar a {
  display: none;
} */
.top-bar a {
  display: inline-block;
  font-size: 1.5rem;
  color: var(--color-theme) !important;
  margin-top: -2px;
}

.table-btn button {
  background: transparent;
  border: none;
  outline: none;
  color: var(--color-theme);
  font-size: 1.5rem;
}

.job-card-list-table .ant-table-wrapper {
  border: 1px solid #e3e3e3;
  border-top: 5px solid var(--color-theme);
  border-radius: 5px;
}

.job-card-list-table .ant-table-wrapper .ant-table-cell {
  /* padding: 10px !important; */
  /* text-align: center; */
}

.job-card-list-table .ant-table-wrapper table {
  border-radius: 0 !important;
}

.job-card-list-table .ant-table-wrapper .ant-table-cell::before {
  height: 100% !important;
  background: var(--color-theme) !important;
}

.job-card-list-table .ant-table-wrapper .ant-table-thead > tr > th,
.job-card-list-table .ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #f3f3f3;
  border-radius: 0 0 !important;
}

.edit-stage-btn,
.edit-stage-btn:hover,
.edit-stage-btn:focus,
.btn.edit-stage-btn:active,
.edit-stage-btn:focus-visible {
  color: var(--color-theme);
  font-size: 1.5rem !important;
  background: transparent !important;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0 !important;
  margin-right: 0.8rem;
}

@media (max-width: 768px) {
  .steps-container {
    max-width: 100%;
    overflow-x: scroll;
  }

  .steps-container .ant-steps-item {
    display: inline-block !important;
    margin-right: 20px !important;
    min-width: 190px;
  }

  .ant-steps-item-container {
    white-space: nowrap !important;
  }

  .job-card-stage-page .top-bar .countdown {
    margin-left: 0;
    flex-wrap: wrap;
  }

  .job-card-stage-page .ant-steps.ant-steps-vertical {
    flex-direction: row !important;
  }

  .job-card-stage-page .ant-steps .ant-steps-item-tail {
    display: none !important;
  }

  .job-card-stage-page .ant-steps .ant-steps-item-title::after {
    position: absolute;
    top: 16px !important;
    inset-inline-start: 100% !important;
    display: block !important;
    width: 9999px !important;
    height: 1px !important;
    background: rgba(5, 5, 5, 0.06);
    content: "";
  }

  .job-card-stage-page .ant-steps .ant-steps-item:last-child {
    margin-right: 0 !important;
    min-width: auto !important;
  }

  .job-card-stage-page .ant-steps-item-active {
    left: 0;
  }

  .job-card-stage-page .ant-steps {
    transition: 1s;
  }

  .job-card-stage-page .step-class-1 .ant-steps .ant-steps-item-active {
    /* transform: translateX(0); */
  }

  .job-card-stage-page .step-class-2 .ant-steps {
    /* transform180px */
  }

  .job-card-stage-page .step-class-3 .ant-steps {
    /* transform380px */
  }

  .job-card-stage-page .step-class-4 .ant-steps {
    /* transform580px */
  }

  .job-card-stage-page .step-class-5 .ant-steps {
    /* transform780px */
  }

  .job-card-stage-page .step-class-6 .ant-steps {
    /* transform900px */
  }

  .job-card-page .job-card-box .box .card-number {
    font-size: 1.3rem;
  }
}

.job-card-accordion .ant-collapse {
  border: none !important;
  border-radius: 0 !important;
  background: transparent !important;
}

.job-card-accordion .ant-collapse-item {
  background: #ffffff;
  border: 1px solid var(--color-theme) !important;
  border-top-width: 5px !important;
  border-radius: 5px !important;
  margin-bottom: 2rem !important;
  overflow: hidden;
}

.job-card-accordion .ant-collapse-header {
  flex-direction: row-reverse;
  align-items: flex-start !important;
  gap: 10px;
  position: relative;
  padding: 0 !important;
  padding-bottom: 1rem !important;
  position: relative;
}

.job-card-accordion .ant-collapse-header .ant-collapse-expand-icon {
  position: absolute;
  top: 12px;
  right: 0;
}

.job-card-accordion .tray-checkbox-add .ant-collapse-expand-icon {
  top: 25px;
  right: 0px;
  margin-right: 10px !important;
}

.job-card-accordion .ant-collapse-header .card-number {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0;
  display: flex;
  align-items: center;
  color: var(--color-theme);
  gap: 10px;
}

.job-card-accordion .ant-collapse-header .ant-collapse-expand-icon {
  width: 25px;
  height: 25px !important;
  padding: 0 !important;
  border-radius: 50%;
  background: var(--color-theme);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.2rem;
  margin-top: 0.5rem;
}

.job-card-accordion .ant-collapse-header .ant-collapse-expand-icon svg {
  font-size: 1.1rem;
  color: #fff !important;
}

.job-card-accordion .ant-collapse-header .card-date {
  font-size: 1.1rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.job-card-accordion .ant-collapse-header .card-date svg {
  color: var(--color-theme);
  font-size: 1.3rem;
  margin-right: 5px;
}

.job-card-accordion p {
  margin-bottom: 0;
  font-weight: 500;
}

.job-card-accordion span {
  font-weight: 500;
}

.job-card-accordion .ant-collapse-content-box {
  padding: 0 !important;
}

.job-card-accordion .inner-collapse .stage-card .top-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
  display: inline-block;
  line-height: 1.5;
}

.job-card-accordion .countdown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.job-card-accordion .countdown .ant-statistic {
  display: flex;
}

.job-card-accordion .countdown .ant-statistic-content {
  font-size: 16px !important;
}

.job-card-accordion .ant-collapse-content {
  border-color: var(--color-theme) !important;
}

.job-card-accordion .inner-collapse .ant-collapse-item {
  /* border: 1px solid #e3e3e3 !important; */
  border: none !important;
  border-bottom: 1px solid #e3e3e3 !important;
  border-radius: 0 !important;
  background: #f3f3f35e;
  margin-bottom: 0 !important;
}

.job-card-accordion .inner-collapse .ant-collapse-header {
  align-items: center !important;
  border: none !important;
}

.job-card-accordion
  .inner-collapse
  .ant-collapse-header
  .ant-collapse-expand-icon {
  background: transparent !important;
}

.job-card-accordion
  .inner-collapse
  .ant-collapse-header
  .ant-collapse-expand-icon
  svg {
  color: var(--color-theme) !important;
  font-size: 0.9rem;
}

.job-card-accordion .inner-collapse .ant-collapse-content-box {
  padding: 12px 1rem !important;
}

.job-card-accordion .inner-collapse .ant-collapse-expand-icon {
  top: 8px;
  right: 10px;
  /* width: auto; */
  height: auto;
}

.job-card-accordion .inner-collapse .ant-collapse-content {
  border-color: #e3e3e3 !important;
}

.job-card-accordion .inner-collapse .ant-collapse-header {
  padding-bottom: 5px !important;
}

.job-card-accordion .inner-collapse .stage-card .stage-content {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0 1rem; */
}

.job-card-accordion .inner-collapse .stage-card .box {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #e3e3e3;
  width: 50%;
  font-size: 15px;
}

.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(1),
.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(2) {
  padding-top: 0;
}

.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(3),
.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(4) {
  padding-bottom: 0;
}

.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(1),
.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(3) {
  border-right: 1px solid #e3e3e3 !important;
}

.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(2),
.job-card-accordion
  .inner-collapse
  .stage-card
  .stage-content
  .box:nth-child(4) {
  padding-left: 10px;
}

.job-card-accordion .inner-collapse .ant-collapse-content-box {
  position: relative;
}

.job-card-accordion .inner-collapse .accepte-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.648);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 1;
  padding: 1rem;
}

.job-card-accordion .inner-collapse .stage-input .box {
  width: 100% !important;
  align-items: center;
}

.job-card-accordion .inner-collapse .stage-input .box:first-child {
  padding-top: 0;
}

.job-card-accordion .inner-collapse .stage-input .box:nth-child(4) {
  padding-bottom: 0;
}

.job-card-accordion .inner-collapse .stage-input .box span:first-child {
  width: 70% !important;
}

.job-card-accordion .inner-collapse .stage-card .stage-input .box:last-child {
  border-bottom: 0 !important;
}

.job-card-accordion .inner-accordion-header p {
  margin-bottom: 5px !important;
}

.job-card-accordion .inner-accordion-header p:last-child {
  margin-bottom: 0 !important;
}

.job-card-accordion .inner-accordion-header {
  font-size: 16px;
}

.job-card-accordion .inner-accordion-header span {
  width: 12%;
  display: inline-block;
}

.job-card-accordion .ant-statistic-title {
  font-size: 12px !important;
}

.job-card-accordion .ant-statistic-content span {
  border: 1px dashed var(--color-theme);
  padding: 0 5px;
  border-radius: 5px;
  width: fit-content;
}

.stage-modal .ant-modal-footer .ant-btn-primary {
  background: var(--color-theme) !important;
}

.try-shifting-heading {
  color: #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
}

.try-shifting-card-box {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.try-shifting-card {
  width: 48%;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--color-theme);
  gap: 5px;
  background: #ffffffe3;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.7rem;
}

.job-card-accordion .ant-collapse-header-text .card-number p {
  font-size: 0.8rem;
  color: #000;
  margin: 0;
}

.job-card-accordion .tray-checkbox,
.tray-shifting-page .tray-checkbox {
  position: absolute;
  top: -5px;
  right: -1.9rem;
  z-index: 10;
}

.job-card-accordion .tray-checkbox {
  top: 3px;
  right: 5px;
}

.job-card-accordion .tray-checkbox-add .tray-checkbox {
  top: 5px;
  right: 10px;
}

.job-card-accordion .tray-checkbox p {
  font-size: 0.8rem;
  background: var(--color-theme);
  color: #fff;
  border-radius: 5px;
  padding: 0 6px;
}

.tray-shifting-page .tray-checkbox .ant-checkbox-inner {
  width: 22px;
  height: 22px;
}

.job-card-accordion .tray-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  /* border-top: 0 !important;
  border-right: 0 !important;
  border-width: 2px !important;
  border-radius: 0 0 0 5px !important; */
}

.job-card-accordion .tray-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.tray-shifting-page .tray-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: var(--color-theme) !important;
  border-color: var(--color-theme) !important;
}

.job-card-accordion .tray-checkbox .ant-checkbox-inner,
.tray-shifting-page .tray-checkbox .ant-checkbox-inner,
.job-card-accordion .tray-checkbox .ant-checkbox-checked:after,
.tray-shifting-page .tray-checkbox .ant-checkbox-checked:after {
  border-color: var(--color-theme) !important;
}

.job-card-accordion .tray-checkbox .ant-checkbox-inner:after,
.tray-shifting-page .tray-checkbox .ant-checkbox-inner:after {
  left: 25% !important;
  width: 8px;
  height: 12px;
}

.tray-shifting-page .box.content p {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  font-size: 13px !important;
  color: #000;
  font-weight: 500;
}

.tray-shifting-page .stage-input .box {
  padding: 10px 0;
  font-weight: 500;
  color: #000;
  font-size: 13px !important;
}

.tray-shifting-page .stage-input .box p {
  font-size: 13px !important;
  padding: 0;
}

.tray-shifting-page .stage-input .box span:first-child {
  width: 70% !important;
}

.tray-shifting-page .stage-content {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px !important;
  color: #000;
  font-weight: 500;
}

.tray-shifting-page .stage-content .box {
  width: 50%;
  padding: 10px 0;
  gap: 5px;
}

.tray-shifting-page .stage-content .box p {
  font-size: 13px !important;
  padding: 0;
}

.tray-shifting-page .stage-content .box:nth-child(1),
.tray-shifting-page .stage-content .box:nth-child(3) {
  border-right: 1px solid #e3e3e3 !important;
}

.tray-shifting-page .stage-content .box:nth-child(2),
.tray-shifting-page .stage-content .box:nth-child(4) {
  padding-left: 10px;
}

.tray-shifting-page .ant-collapse-item {
  background: #ffffff;
  border: 1px solid var(--color-theme) !important;
  border-top-width: 5px !important;
  border-radius: 5px !important;
  margin-bottom: 2rem !important;
  overflow: hidden;
}

.tray-shifting-page .ant-collapse-item .ant-collapse-header {
  flex-direction: row-reverse !important;
  position: relative;
}

.tray-shifting-page .ant-collapse-item .ant-collapse-expand-icon {
  /* position: absolute;
  bottom: 10px;
  right: 10px; */
  /* z-index: 9; */
  margin-top: 1.7rem;
  width: 20px;
  height: 20px !important;
  padding: 0 !important;
  border-radius: 50%;
  background: var(--color-theme);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tray-shifting-page .ant-collapse-item .ant-collapse-expand-icon svg {
  color: #fff !important;
}

.tray-shifting-floting-btn {
  position: sticky;
  bottom: 0;
  left: 53%;
  transform: translateX(-53%);
  /* right: 0; */
  text-align: center;
  padding: 1rem;
  width: auto;
  transform: 0.5s all;
  background: #fff;
}
.bodycollapse .tray-shifting-floting-btn {
  left: 60%;
  transform: translateX(-60%);
}
.job-card-accordion .ant-collapse-header .card-number img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #f3f3f3;
  margin-right: 10px;
  object-fit: cover;
}

.ant-drawer-content-wrapper {
  height: auto !important;
}

.tray-shifting-drawer {
  height: 90vh !important;
}

.tray-shifting-drawer .ant-drawer-header,
.tray-shifting-drawer .ant-drawer-body {
  padding: 10px !important;
}

.tray-shifting-drawer .ant-drawer-body .job-card-box {
  border: 1px solid var(--color-theme);
}

.tray-shifting-drawer .ant-drawer-body .job-card-box img {
  width: 50px;
  height: 50px;
}

.tray-shifting-drawer .ant-drawer-body .tray-checkbox {
  top: 5px;
  right: 0;
}

.tray-shifting-drawer .ant-drawer-close {
  margin: 0;
}

.tray-shifting-drawer .ant-drawer-body .tray-checkbox svg {
  font-size: 1.5rem;
}

.tray-shifting-drawer .ant-drawer-body {
  padding-bottom: 5rem !important;
}

.rejection-card {
  border: 1px solid #e3e3e3;
  border-top: 5px solid var(--color-theme) !important;
}

.rejection-card .box .ant-select-selector {
  border-color: #bbbbbb !important;
}

.rejection-card .job-card-box .box svg {
  color: #bbbbbb !important;
  font-size: 1rem !important;
}

.rejection-card .job-card-box .box {
  padding-bottom: 10px;
}

.rejection-card .job-card-box .box span.w-100 {
  width: 100% !important;
}

.rejection-box .job-card-box .box .card-number {
  font-size: 1.1rem;
}

.rejection-box .job-card-box .box img {
  width: 50px;
  height: 50px;
}

.jobcard-stage-title p,
.jobcard-stage-title p div,
.jobcard-stage-title span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.jobcard-stage-title p div {
  gap: 10px;
}

.jobcard-stage-title p {
  gap: 0;
  flex-direction: column;
  align-items: flex-start;
}

.jobcard-stage-title p svg {
  color: var(--color-theme);
}
.save-jobcard-img {
  width: 70px;
}
.dispatch-save-jobcard-modal .ant-modal-footer {
  text-align: center;
}
.dispatch-save-jobcard-modal .ant-modal-footer .ant-btn-default {
  background: var(--color-theme);
  color: #fff !important;
  border-color: var(--color-theme) !important;
}

.create-batch-box-desk {
  border: 1px solid #e3e3e3;
  border-top: 2px solid var(--color-theme);
  border-radius: 5px;
  padding: 10px;
}
.create-batch-box-desk .create-batch-desk-card {
  border-bottom: 1px solid var(--color-theme);
  display: flex;
  /* align-items: center; */
  padding: 10px;
  padding-top: 0;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.create-batch-box-desk .create-batch-desk-card:last-child {
  margin-bottom: 0;
}
.create-batch-box-desk .create-batch-desk-card div {
  width: 25%;
}
.create-batch-box-desk .create-batch-desk-card div label {
  font-size: 0.8rem;
  margin-bottom: 5px;
}
.create-batch-box-desk .create-batch-desk-card div p {
  margin-bottom: 0;
  color: #000;
}
.download-pdf-btn{
  font-size: .8rem !important;
}
.download-pdf-btn svg{
  font-size: .9rem;
  margin-top: -1px;
}
@media (max-width: 568px) {
  .create-batch-box-desk .create-batch-desk-card {
    flex-wrap: wrap;
    padding: 0;
    padding-bottom: 10px;
  }
  .create-batch-box-desk .create-batch-desk-card div label{
    font-size: .6rem;
    margin-bottom: 0;
  }
  .create-batch-box-desk .create-batch-desk-card div .ant-input-affix-wrapper{
    padding: 5px 8px;
    font-size: .7rem;
  }
  .create-batch-box-desk .create-batch-desk-card div p{
    font-size: .7rem;
  }
  .create-batch-box-desk .create-batch-desk-card div {
    width: 50%;
    padding: 7px 10px;
  }
  .create-batch-box-desk .create-batch-desk-card div:first-child {
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    padding-top: 0;
  }
  .create-batch-box-desk .create-batch-desk-card div:nth-child(2) {
    padding-top: 0;
    border-bottom: 1px solid #e3e3e3;
  }
  .create-batch-box-desk .create-batch-desk-card div:nth-child(3) {
    border-right: 1px solid #e3e3e3;
    padding-bottom: 0;
  }
  .create-batch-box-desk .create-batch-desk-card div:nth-child(4) {
    padding-bottom: 0;
  }
  .ant-picker-dropdown {
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 100% !important;
    max-width: 320px !important;
  }
  .ant-picker-dropdown .ant-picker-panels {
    flex-direction: column !important;
  }
  .jobcard-stage-title p {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .jobcard-stage-title span {
    font-size: 0.8rem;
  }

  .job-card-accordion .inner-accordion-header span {
    width: 50%;
  }

  .job-card-accordion .ant-collapse-header .card-number img {
    aspect-ratio: 1/1;
    width: 40px;
    height: auto;
  }

  .job-card-accordion .ant-collapse-header .card-number {
    font-size: 1rem;
    flex-wrap: wrap;
    padding-right: 4.5rem;
  }

  .job-card-accordion .ant-collapse-header .card-number span svg {
    width: 30px;
  }

  .job-card-accordion .ant-collapse-header .card-number span p {
    font-size: 0.8rem;
    padding-right: 3rem;
  }

  .job-card-accordion .countdown .ant-statistic,
  .job-card-accordion .countdown {
    display: block;
  }

  .job-card-accordion .card-heading {
    display: flex !important;
  }

  .tray-shifting-floting-btn {
    width: 100%;
    left: 0;
    transform: translate(0);
  }
}

.warning-icon {
  color: rgb(238, 238, 30);
}

/* ****************************************************** New Changes ***************************************************** */
@media (max-width: 420px) {
  .job-card-accordion
    .list-all
    :where(.css-dev-only-do-not-override-txh9fw).ant-select-single {
    width: 100% !important;
  }

  .job-card-accordion
    :where(.css-dev-only-do-not-override-txh9fw).ant-statistic
    .ant-statistic-content
    .ant-statistic-content-value {
    width: 100% !important;
  }

  .job-card-accordion .list-all .list-all-width {
    width: 100%;
  }

  .tab-filter .nav-pills {
    width: 100% !important;
    flex-wrap: nowrap;
  }

  .nav-pills .nav-link.active {
    background-color: var(--color-theme);
    border-radius: 2px;
    color: #fff !important;
    height: 100%;
  }
}

@media (max-width: 360px) {
  .job-card-accordion .ant-collapse-header .ant-collapse-expand-icon {
    position: absolute;
    top: 12px;
    right: 0px;
  }

  .job-card-accordion
    .list-all
    :where(.css-dev-only-do-not-override-txh9fw).ant-select-single {
    width: 100% !important;
  }

  .job-card-accordion
    :where(.css-dev-only-do-not-override-txh9fw).ant-statistic
    .ant-statistic-content
    .ant-statistic-content-value {
    width: 100% !important;
  }

  .tab-filter .nav-pills {
    width: 100% !important;
    flex-wrap: nowrap;
  }

  .nav-pills .nav-link.active {
    background-color: var(--color-theme);
    border-radius: 2px;
    color: #fff !important;
    height: 100%;
  }
}
