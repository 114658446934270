.user-search-box .search-box,
.user-search-box {
  width: 60%;
}

.staff-list-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 1rem;
  /* justify-content: space-between; */
}

.staff-list-box .spare-card {
  width: 32%;
}

.staff-list-box .spare-card:last-child {
  margin-bottom: 1rem;
}

.staff-list-box .spare-card :is(.ant-upload-wrapper, .ant-upload) {
  height: 100% !important;
}

.staff-list-box .spare-card .ant-upload {
  padding: 2px;
}

.staff-list-box .spare-card .ant-upload img {
  height: 100% !important;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 568px) {

  .user-search-box .search-box,
  .user-search-box {
    width: 100%;
  }

  .user-search-box {
    flex-wrap: wrap;
  }

  .staff-list-box .spare-card:last-child {
    margin-bottom: 0;
  }

  .staff-list-box .spare-card {
    width: 47%;
  }

  .staff-list-box .spare-card button {
    width: 100%;
  }
}